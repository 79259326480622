import React, { Component } from 'react';

import './App.scss';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import { block } from 'bem-cn';
import * as Yup from 'yup';
import * as Validation from './Validation';

import axios from 'axios';

const currentDate = (new Date()).toISOString().substr(0, 10);


function validate(value, func, context) {
  const {result, error} = func(value);
  if (result) {
    return true;
  }
  return context.createError({path: context.path, message: error.message})
}

const SignupSchema = Yup.object().shape({
  fio: Yup.string()
    .required('Введите ФИО'),
  inn: Yup.string()
    .test('inn', 'Некорректный ИНН', function(value) {return validate(value, Validation.validateInn, this)})
    .required('Введите ИНН'),
  passport: Yup.string()
    .min(10, 'Введено мало символов')
    .required('Введите данные паспорта'),
  email: Yup.string()
    .email('Некорректный Email')
    .required('Введите Email'),
  phone: Yup.string()
    .matches(/\+7\d{10}/i, 'Неверный формат телефона. Должен начинаться с +7')
    .required('Укажите телефон'),
  address: Yup.string()
    .min(20, 'Введено мало символов')
    .required('Введите ваш адрес'),
  bic: Yup.string()
    .test('bic', 'Некорректный БИК', function (value) {return validate(value, Validation.validateBik, this)})
    .required('Введите БИК банка'),
  account_number: Yup.string()
    .matches(/40817810\d*/i, 'Номер счёта должен начинаться с 40817810')
    .test('acc', 'Некорректный БИК', function (value) {return validate({
      bik: this.parent.bic | '',
      rs: value ? value : ''
    }, Validation.validateRs, this)})
    .required('Введите номер счёта'),
  contract_date: Yup.date()
    .min('2019-04-01', 'Дата не должна быть раньше сегодня'),
  comission_rate: Yup.number()
    .min(1, 'Не менее 1%')
    .max(15, 'Не более 15%')
    .required('Введите размер комиссии')
});

class App extends Component {
  render() {
    const b = block('form');
    return (
      <div className={b()}>
        <Formik
          validationSchema={SignupSchema}
          validateOnBlur={true}
          onSubmit={(values, actions) => {
            const bodyFormData = new FormData();
            for ( var key in values ) {
              bodyFormData.append(key, values[key]);
            }
            axios({
              method: 'post',
              url: 'https://hooks.zapier.com/hooks/catch/4599315/7r67ef/',
              data: bodyFormData,
              config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
              .then(() => {
                alert('Отправленно');
                actions.setSubmitting(false);
              })
              .catch(() => {
                alert('Ошибка сервера');
                actions.setSubmitting(false);
              })
            actions.resetForm();
          }}
          initialValues={{
            oktell_login: '', fio: '', inn: '', passport: '', email: '',
            phone: '', address: '', bic: '', account_number: '',
            contract_date: currentDate, comission_rate: 2
          }}
          render={({isSubmitting, isValid}) => (
            <Form>
              <h1>Заведение нового оператора</h1>
              <h2>Основные данные</h2>
              <Item name='oktell_login' type='text' label='Логин оператора в Октелл' />
              <Item name='fio' type='text' label='ФИО (полностью)' />
              <Item name='inn' type='text' label='ИНН' 
                caption='ИНН физлица - последовательность из 12 цифр, первые две представляют собой код субъекта федерации' 
              />
              <Item name='passport' type='textarea' 
                label='Пасспорт, кем и когда выдан' 
                caption='Пример: Паспорт 4500 123456, выдан ФМС Центрального района г.Сочи, 01.01.2010'
              />
              <Item name='email' type='email' label='Ваш Email' />
              <Item name='phone' type='phone' label='Номер телефона' />
              <Item name='address' rows={2} type='textarea' label='Фактический адрес' 
                caption='Индекс, город, улица, дом. Туда будут направляться документы'
              />
              <h2>Банковские данные</h2>
              <Item name='bic' type='text' label='БИК Банка' 
                caption='БИК должен состоять из 9 цифр и начинаться с 04' 
              />
              <Item name='account_number' type='text' label='Расчётный счёт' 
                caption='Номер, который начинается на 40817810'
              />
              <h2>Условия договора</h2>
              <Item name='contract_date' type='date' label='Дата договора' 
                caption='Если не указать, сегодняшний день'
              />
              <Item name='comission_rate' type='text' label='Комиссия Атласа' 
                caption='Если не указать, 2%'
              />
              <button type="submit" disabled={isSubmitting || !isValid}>
                Добавить
              </button>

            </Form>
          )}
        />
      </div>
    );
  }
}

const Item = ({name, type, label, caption, rows}) => <div className={'form__item'}>
  <label htmlFor={name}>{label}</label>
  {caption && <div className='form__caption' >{caption}</div>}
  {type === 'textarea' ?
    <Field component={type} name={name} rows={rows} />
    : <Field type={type} name={name} />
}
  <ErrorMessage className='err' name={name} component="div" />
</div>

export default App;
